var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.userId != null
    ? _c(
        "div",
        { staticClass: "chat__header" },
        [
          _c(
            "vs-navbar",
            {
              staticClass: "p-4 flex navbar-custom",
              attrs: { color: "white", type: "flat" },
            },
            [
              _c(
                "div",
                { staticClass: "relative flex mr-4" },
                [
                  _vm.isSidebarCollapsed
                    ? _c("feather-icon", {
                        staticClass: "mr-4 cursor-pointer",
                        attrs: { icon: "MenuIcon" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.$emit("openContactsSidebar")
                          },
                        },
                      })
                    : _vm._e(),
                  _c("vs-avatar", {
                    staticClass: "m-0 border-2 border-solid border-white",
                    attrs: {
                      size: "40px",
                      src: require("@/assets/images/portrait/small/" +
                        _vm.userImg),
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.$emit("showProfileSidebar", _vm.userId)
                      },
                    },
                  }),
                  _c("div", {
                    staticClass:
                      "h-3 w-3 border-white border border-solid rounded-full absolute right-0 bottom-0",
                    class: "bg-" + _vm.getStatusColor(false),
                  }),
                ],
                1
              ),
              _c("h6", [_vm._v(_vm._s(_vm.contactName))]),
              _c("vs-spacer"),
              _c("feather-icon", {
                staticClass: "cursor-pointer",
                attrs: {
                  icon: "StarIcon",
                  svgClasses: [
                    { "text-warning stroke-current": _vm.isPinnedLocal },
                    "w-6",
                    "h-6",
                  ],
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.toggleIsPinned.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }