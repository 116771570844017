var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "border border-solid d-theme-border-grey-light rounded relative overflow-hidden",
      attrs: { id: "chat-app" },
    },
    [
      _c(
        "vs-sidebar",
        {
          staticClass: "items-no-padding",
          attrs: {
            parent: "#chat-app",
            "click-not-close": _vm.clickNotClose,
            "hidden-background": _vm.clickNotClose,
            id: "chat-list-sidebar",
          },
          model: {
            value: _vm.isChatSidebarActive,
            callback: function ($$v) {
              _vm.isChatSidebarActive = $$v
            },
            expression: "isChatSidebarActive",
          },
        },
        [
          _c("user-profile", {
            staticClass: "user-profile-container",
            attrs: {
              active: _vm.activeProfileSidebar,
              userId: _vm.userProfileId,
            },
            on: { closeProfileSidebar: _vm.closeProfileSidebar },
          }),
          _c(
            "div",
            { staticClass: "chat__profile-search flex p-4" },
            [
              _c(
                "div",
                { staticClass: "relative inline-flex" },
                [
                  _c("vs-avatar", {
                    staticClass: "m-0 border-2 border-solid border-white",
                    attrs: {
                      src: require("@/assets/images/portrait/small/" +
                        _vm.activeUserImg),
                      size: "40px",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.showProfileSidebar(_vm.activeUserId)
                      },
                    },
                  }),
                  _c("div", {
                    staticClass:
                      "h-3 w-3 border-white border border-solid rounded-full absolute right-0 bottom-0",
                    class: "bg-" + _vm.getStatusColor(true),
                  }),
                ],
                1
              ),
              _c("vs-input", {
                staticClass: "w-full mx-5 input-rounded-full no-icon-border",
                attrs: {
                  icon: "icon-search",
                  "icon-pack": "feather",
                  placeholder: "Search or start a new chat",
                },
                model: {
                  value: _vm.searchQuery,
                  callback: function ($$v) {
                    _vm.searchQuery = $$v
                  },
                  expression: "searchQuery",
                },
              }),
            ],
            1
          ),
          _c("vs-divider", { staticClass: "d-theme-border-grey-light m-0" }),
          _c(
            "VuePerfectScrollbar",
            {
              staticClass: "chat-scroll-area pt-4",
              attrs: { settings: _vm.settings },
            },
            [
              _c("div", { staticClass: "chat__chats-list mb-8" }, [
                _c("h3", { staticClass: "text-primary mb-5 px-4" }, [
                  _vm._v("Chats"),
                ]),
                _c(
                  "ul",
                  { staticClass: "chat__active-chats bordered-items" },
                  _vm._l(_vm.sorted, function (contact, index) {
                    return _c(
                      "li",
                      {
                        key: index,
                        staticClass: "cursor-pointer",
                        on: {
                          click: function ($event) {
                            return _vm.updateActiveChatUser(contact.id)
                          },
                        },
                      },
                      [
                        _c("chat-contact", {
                          attrs: {
                            contact: contact,
                            lastMessaged: _vm.chatLastMessaged(contact.id).time,
                            unseenMsg: _vm.chatUnseenMessages(contact.id),
                            isActiveChatUser: _vm.isActiveChatUser(contact.id),
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "chat__contacts" }, [
                _c("h3", { staticClass: "text-primary mb-5 px-4" }, [
                  _vm._v("Contacts"),
                ]),
                _c(
                  "ul",
                  { staticClass: "chat__contacts bordered-items" },
                  _vm._l(_vm.chatContacts, function (contact) {
                    return _c(
                      "li",
                      {
                        key: contact.id,
                        staticClass: "cursor-pointer",
                        on: {
                          click: function ($event) {
                            return _vm.updateActiveChatUser(contact.id)
                          },
                        },
                      },
                      [_c("chat-contact", { attrs: { contact: contact } })],
                      1
                    )
                  }),
                  0
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "chat__bg app-fixed-height chat-content-area border border-solid d-theme-border-grey-light border-t-0 border-r-0 border-b-0",
          class: {
            "sidebar-spacer--wide": _vm.clickNotClose,
            "flex items-center justify-center": _vm.activeChatUser === null,
          },
        },
        [
          _vm.activeChatUser
            ? [
                _c(
                  "div",
                  { staticClass: "chat__navbar" },
                  [
                    _c("chat-navbar", {
                      attrs: {
                        isSidebarCollapsed: !_vm.clickNotClose,
                        "user-id": _vm.activeChatUser,
                        isPinnedProp: _vm.isChatPinned,
                      },
                      on: {
                        openContactsSidebar: function ($event) {
                          return _vm.toggleChatSidebar(true)
                        },
                        showProfileSidebar: _vm.updateUserProfileId,
                        toggleIsChatPinned: _vm.toggleIsChatPinned,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "VuePerfectScrollbar",
                  {
                    ref: "chatLogPS",
                    staticClass:
                      "chat-content-scroll-area border border-solid d-theme-border-grey-light",
                    attrs: { settings: _vm.settings },
                  },
                  [
                    _c(
                      "div",
                      { ref: "chatLog", staticClass: "chat__log" },
                      [
                        _vm.activeChatUser
                          ? _c("chat-log", {
                              attrs: { userId: _vm.activeChatUser },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "chat__input flex p-4 bg-white" },
                  [
                    _c("vs-input", {
                      staticClass: "flex-1",
                      attrs: { placeholder: "Type Your Message" },
                      on: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.sendMsg.apply(null, arguments)
                        },
                      },
                      model: {
                        value: _vm.typedMessage,
                        callback: function ($$v) {
                          _vm.typedMessage = $$v
                        },
                        expression: "typedMessage",
                      },
                    }),
                    _c(
                      "vs-button",
                      {
                        staticClass: "bg-primary-gradient ml-4",
                        attrs: { type: "filled" },
                        on: { click: _vm.sendMsg },
                      },
                      [_vm._v("Send")]
                    ),
                  ],
                  1
                ),
              ]
            : [
                _c(
                  "div",
                  { staticClass: "flex flex-col items-center" },
                  [
                    _c("feather-icon", {
                      staticClass: "mb-4 bg-white p-8 shadow-md rounded-full",
                      attrs: {
                        icon: "MessageSquareIcon",
                        svgClasses: "w-16 h-16",
                      },
                    }),
                    _c(
                      "h4",
                      {
                        staticClass:
                          " py-2 px-4 bg-white shadow-md rounded-full cursor-pointer",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.toggleChatSidebar(true)
                          },
                        },
                      },
                      [_vm._v("Start Conversation")]
                    ),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }