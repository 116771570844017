var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "parentx-demo-2" } },
    [
      _c(
        "vs-sidebar",
        {
          staticClass: "items-no-padding",
          attrs: {
            parent: "#chat-app",
            "position-right": !_vm.isActiveUser,
            "hidden-background": false,
            id: "chat-profile-sidebar",
          },
          model: {
            value: _vm.activeLocal,
            callback: function ($$v) {
              _vm.activeLocal = $$v
            },
            expression: "activeLocal",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "header-sidebar relative flex flex-col p-0",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c("feather-icon", {
                attrs: {
                  icon: "XIcon",
                  svgClasses: "m-2 cursor-pointer absolute top-0 right-0",
                },
                on: {
                  click: function ($event) {
                    return _vm.$emit("closeProfileSidebar", false)
                  },
                },
              }),
              _c(
                "div",
                { staticClass: "relative inline-flex mx-auto mb-5 mt-6" },
                [
                  _c("vs-avatar", {
                    staticClass:
                      "m-0 border-white border-2 border-solid shadow-md",
                    attrs: {
                      src: require("@/assets/images/portrait/small/" +
                        _vm.userImg),
                      size: "70px",
                    },
                  }),
                  _c("div", {
                    staticClass:
                      "h-5 w-5 border-white border-2 border-solid rounded-full absolute right-0 bottom-0",
                    class: "bg-" + _vm.getStatusColor(_vm.isActiveUser),
                  }),
                ],
                1
              ),
              _c("h4", { staticClass: "mr-2 self-center" }, [
                _vm._v(_vm._s(_vm.userName)),
              ]),
            ],
            1
          ),
          _c(
            "VuePerfectScrollbar",
            { staticClass: "scroll-area", attrs: { settings: _vm.settings } },
            [
              _c(
                "div",
                { staticClass: "p-8" },
                [
                  _c(
                    "h6",
                    {
                      staticClass: "mb-2",
                      class: { "ml-4": _vm.isActiveUser },
                    },
                    [_vm._v("About")]
                  ),
                  _vm.isActiveUser
                    ? _c("vs-textarea", {
                        staticClass: "mb-10",
                        attrs: {
                          counter: "120",
                          maxlength: "120",
                          "counter-danger": _vm.counterDanger,
                          rows: "5",
                        },
                        on: {
                          "update:counterDanger": function ($event) {
                            _vm.counterDanger = $event
                          },
                          "update:counter-danger": function ($event) {
                            _vm.counterDanger = $event
                          },
                        },
                        model: {
                          value: _vm.about,
                          callback: function ($$v) {
                            _vm.about = $$v
                          },
                          expression: "about",
                        },
                      })
                    : _c("p", [_vm._v(_vm._s(_vm.about))]),
                  _vm.isActiveUser
                    ? _c("div", { staticClass: "userProfile__status" }, [
                        _c("h6", { staticClass: "mb-4" }, [_vm._v("Status")]),
                        _c("ul", [
                          _c(
                            "li",
                            { staticClass: "mb-2" },
                            [
                              _c(
                                "vs-radio",
                                {
                                  attrs: {
                                    "vs-value": "online",
                                    color: "success",
                                  },
                                  model: {
                                    value: _vm.status,
                                    callback: function ($$v) {
                                      _vm.status = $$v
                                    },
                                    expression: "status",
                                  },
                                },
                                [_vm._v("Active")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "li",
                            { staticClass: "mb-2" },
                            [
                              _c(
                                "vs-radio",
                                {
                                  attrs: {
                                    "vs-value": "do not disturb",
                                    color: "danger",
                                  },
                                  model: {
                                    value: _vm.status,
                                    callback: function ($$v) {
                                      _vm.status = $$v
                                    },
                                    expression: "status",
                                  },
                                },
                                [_vm._v("Do Not Disturb")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "li",
                            { staticClass: "mb-2" },
                            [
                              _c(
                                "vs-radio",
                                {
                                  attrs: {
                                    "vs-value": "away",
                                    color: "warning",
                                  },
                                  model: {
                                    value: _vm.status,
                                    callback: function ($$v) {
                                      _vm.status = $$v
                                    },
                                    expression: "status",
                                  },
                                },
                                [_vm._v("Away")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "li",
                            { staticClass: "mb-2" },
                            [
                              _c(
                                "vs-radio",
                                {
                                  attrs: {
                                    "vs-value": "offline",
                                    color: "grey",
                                  },
                                  model: {
                                    value: _vm.status,
                                    callback: function ($$v) {
                                      _vm.status = $$v
                                    },
                                    expression: "status",
                                  },
                                },
                                [_vm._v("Offline")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }