var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.chatData
    ? _c(
        "div",
        { staticClass: "m-8", attrs: { id: "component-chat-log" } },
        _vm._l(_vm.chatData.msg, function (msg, index) {
          return _c(
            "div",
            { key: index, staticClass: "msg-grp-container" },
            [
              _vm.chatData.msg[index - 1]
                ? [
                    !_vm.isSameDay(msg.time, _vm.chatData.msg[index - 1].time)
                      ? _c("vs-divider", [
                          _c("span", [_vm._v(_vm._s(_vm.toDate(msg.time)))]),
                        ])
                      : _vm._e(),
                    !_vm.hasSentPreviousMsg(
                      _vm.chatData.msg[index - 1].isSent,
                      msg.isSent
                    )
                      ? _c("div", { staticClass: "spacer mt-8" })
                      : _vm._e(),
                  ]
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "flex items-start",
                  class: [{ "flex-row-reverse": msg.isSent }],
                },
                [
                  _vm.chatData.msg[index - 1]
                    ? [
                        !_vm.hasSentPreviousMsg(
                          _vm.chatData.msg[index - 1].isSent,
                          msg.isSent
                        ) ||
                        !_vm.isSameDay(
                          msg.time,
                          _vm.chatData.msg[index - 1].time
                        )
                          ? [
                              _c("vs-avatar", {
                                staticClass: "m-0 flex-shrink-0",
                                class: msg.isSent
                                  ? "sm:ml-5 ml-3"
                                  : "sm:mr-5 mr-3",
                                attrs: {
                                  size: "40px",
                                  src: _vm.senderImg(msg.isSent),
                                },
                              }),
                            ]
                          : _vm._e(),
                      ]
                    : _vm._e(),
                  index == 0
                    ? [
                        _c("vs-avatar", {
                          staticClass: "m-0 flex-shrink-0",
                          class: msg.isSent ? "sm:ml-5 ml-3" : "sm:mr-5 mr-3",
                          attrs: {
                            size: "40px",
                            src: _vm.senderImg(msg.isSent),
                          },
                        }),
                      ]
                    : _vm._e(),
                  _vm.chatData.msg[index - 1]
                    ? [
                        !(
                          !_vm.hasSentPreviousMsg(
                            _vm.chatData.msg[index - 1].isSent,
                            msg.isSent
                          ) ||
                          !_vm.isSameDay(
                            msg.time,
                            _vm.chatData.msg[index - 1].time
                          )
                        )
                          ? _c("div", { staticClass: "mr-16" })
                          : _vm._e(),
                      ]
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass:
                        "msg break-words relative shadow-md rounded py-3 px-4 mb-2 rounded-lg max-w-sm",
                      class: {
                        "bg-primary-gradient text-white": msg.isSent,
                        "border border-solid border-grey-light bg-white":
                          !msg.isSent,
                      },
                    },
                    [_c("span", [_vm._v(_vm._s(msg.textContent))])]
                  ),
                ],
                2
              ),
            ],
            2
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }