var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "chat__contact flex items-center px-2 py-3",
      class: {
        "bg-primary-gradient text-white shadow-lg": _vm.isActiveChatUser,
      },
    },
    [
      _c(
        "div",
        { staticClass: "contact__avatar mr-3" },
        [
          _c("vs-avatar", {
            staticClass: "border-2 border-solid border-white",
            attrs: {
              src: require("@/assets/images/portrait/small/" + _vm.contact.img),
              size: "42px",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "contact__container w-full flex items-center justify-between overflow-hidden",
        },
        [
          _c(
            "div",
            { staticClass: "contact__info flex flex-col truncate w-5/6" },
            [
              _c(
                "h5",
                {
                  staticClass: "font-semibold",
                  class: { "text-white": _vm.isActiveChatUser },
                },
                [_vm._v(_vm._s(_vm.contact.name))]
              ),
              _c("span", { staticClass: "truncate" }, [
                _vm._v(_vm._s(_vm.contact.about)),
              ]),
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "chat__contact__meta flex self-start flex-col items-end w-1/6",
            },
            [
              _c("span", { staticClass: "whitespace-no-wrap" }, [
                _vm._v(_vm._s(_vm._f("date")(_vm.lastMessaged))),
              ]),
              _vm.unseenMsg
                ? _c("vs-avatar", {
                    attrs: {
                      color: "primary",
                      text: "" + _vm.unseenMsg,
                      size: "20px",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }